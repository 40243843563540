import React from 'react'
import StartPage from '../pagesComponents/start'
import Layout from '../layout'

const Start = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const bookingId = params.get('bookingId')?.toString() || null
	const eventId = params.get('eventId')?.toString() || null
	return (
		<Layout theme="light" title="Start Playing at Fairgame">
			<StartPage bookingId={bookingId} eventId={eventId} />
		</Layout>
	)
}

export default Start
