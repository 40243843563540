import * as React from 'react'
import {
	removeBookingId,
	removeEventId,
	setBookingId,
	setErrorMessage,
	setEventId,
	setTempGroup
} from '../helpers/database'
import { useEffect } from 'react'
import Splash from '../splash'
import { useAuth } from '../hooks/useAuth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createGroup } from '../services/group'
import { navigate } from 'gatsby'
import { errorToastOptions } from '../helpers'
import { useToast } from '@chakra-ui/react'
import eventTracking from '../services/eventTracking'

const StartPage = ({ bookingId, eventId }) => {
	const toast = useToast()
	const queryClient = useQueryClient()
	const { refreshProfile, profile } = useAuth()

	const createGroupMutation = useMutation(createGroup, {
		onSuccess: async (data) => {
			eventTracking('group_created', {
				group_id: data?.id
			})

			setTempGroup(data)

			removeBookingId()
			removeEventId()

			await Promise.all([
				refreshProfile(),
				queryClient.invalidateQueries(['groups'], {
					exact: true
				})
			])

			if (data.preCreated) {
				// Means the team was created before
				return navigate(`/groups/${data?.id}/?joined=true`)
			} else {
				return navigate(`/groups/${data?.id}/group-created`)
			}
		},
		onError: (error) => {
			removeBookingId()
			removeEventId()
			if (error?.message?.includes('already exists')) {
				setErrorMessage('group-exist', error.message)
			} else {
				toast({
					description: error.message || error,
					...errorToastOptions
				})
			}
			return navigate('/groups')
		}
	})

	useEffect(() => {
		let isMounted = true
		if (isMounted) {
			if (!!bookingId || !!eventId) {
				if (!!bookingId) {
					setBookingId(bookingId)
					if (profile?.id) {
						createGroupMutation.mutate({ bookingId })
					} else {
						navigate('/login/')
					}
				}

				if (!!eventId) {
					setEventId(eventId)
					if (profile?.id) {
						createGroupMutation.mutate({ eventId })
					} else {
						navigate('/login/')
					}
				}
				return
			}
			navigate('/')
		}
		return () => {
			isMounted = false
		}
	}, [profile])

	return <Splash isLoading={createGroupMutation.isLoading} />
}

export default StartPage
